import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { createPinia } from "pinia";
import { useAppStore } from "@/stores/app";
import { aboutApi } from "@/api/about";
import * as Sentry from "@sentry/vue";

const pinia = createPinia();

aboutApi.get().then((about) => {
    let app = createApp(App);
    Sentry.init({
        app,
        dsn: about.configuration.sentry.dsn,
        tunnel: "/tunnel",
        release: about.configuration.sentry.release,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        environment: about.configuration.sentry.environment,
        enabled: true,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        debug: about.configuration.sentry.debug
    });

    app = app.use(pinia).use(vuetify).use(router);
    const appStore = useAppStore();
    appStore.load(about);
    app.mount("#app");
});
