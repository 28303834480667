import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "",
        redirect: "/auth/login",
    },
    {
        path: "/embed/:type/:id",
        name: "home",
        component: () => import("../views/dashboard/index.vue"),
    },
    {
        path: "/public/forms/register/:type/:id",
        name: "Public Registration",
        component: () => import("../views/public/forms/register/index.vue"),
    },
    {
        path: "/public/forms/register/edit/:id/:token",
        name: "Edit Registration",
        props: true,
        component: () => import("../views/public/registration/edit.vue"),
    },
    {
        path: "/public/payments/pay/:id",
        name: "Pay Outstanding Balance",
        component: () => import("../views/public/payments/pay-view.vue"),
    },
    {
        path: "/public/payments/:id/payment-complete",
        name: "Payment Complete",
        component: () =>
            import("../views/public/payments/payment-complete.vue"),
    },
    {
        path: "/public/payments/:id/payment-send-check",
        name: "Please Send Check",
        component: () =>
            import("../views/public/payments/payment-send-check.vue"),
    },
    {
        path: "/public/invoices/pay/:id",
        name: "Pay Outstanding Balance",
        component: () => import("../views/public/invoices/pay.vue"),
    },
    {
        path: "/public/invoices/:id/payment-complete",
        name: "Payment Complete",
        component: () =>
            import("../views/public/invoices/payment-complete.vue"),
    },
    {
        path: "/public/invoices/:id/payment-send-check",
        name: "Please Send Check",
        component: () =>
            import("../views/public/invoices/payment-send-check.vue"),
    },
    {
        path: "/public/registration/registration-complete",
        name: "Registration Complete",
        component: () =>
            import("../views/public/registration/registration-complete.vue"),
    },
    {
        path: "/auth/login",
        name: "Login",
        component: () => import("../views/auth/login.vue"),
    },
    {
        path: "/admin",
        name: "Admin",
        component: () => import("../views/admin/admin-index.vue"),
        children: [
            {
                path: "check-in",
                name: "Check In",
                component: () =>
                    import("../views/admin/check-in/check-in-index.vue"),
                children: [
                    {
                        path: "",
                        name: "Check In Index",
                        redirect: { name: "Event List" },
                    },
                    {
                        path: "events",
                        name: "Event List",
                        component: () =>
                            import(
                                "../components/admin/check-in/event-list.vue"
                            ),
                    },
                    {
                        path: "event/:eventId",
                        name: "Registration Search",
                        component: () =>
                            import(
                                "../components/admin/check-in/check-in-search.vue"
                            ),
                        props: true,
                    },
                    {
                        path: "pay/:eventId/:registrationId",
                        name: "Payment",
                        component: () =>
                            import(
                                "@/components/admin/check-in/check-in-pay.vue"
                            ),
                        props: true,
                    },
                ],
            },
            {
                path: "events",
                name: "Events",
                component: () => import("../views/admin/events/list.vue"),
            },
            {
                path: "events/:eventId/registrations",
                name: "Registrations",
                component: () =>
                    import("../views/admin/registrations/list.vue"),
            },
            {
                path: "events/:eventId/registrations/:attendeeId",
                name: "Registration",
                component: () =>
                    import("../views/admin/registrations/index.vue"),
            },
            {
                path: "events/:eventId/functions",
                name: "Functions",
                component: () => import("../views/admin/functions/list.vue"),
            },
            {
                path: "events/:eventId/functions/create",
                name: "Create Function",
                component: () => import("../views/admin/functions/create.vue"),
            },
            {
                path: "events/:eventId/functions/:functionId",
                name: "Edit Function",
                component: () => import("../views/admin/functions/edit.vue"),
            },
            {
                path: "events/:eventId/functions/:functionId/speakers",
                name: "Speakers",
                component: () => import("../views/admin/speakers/list.vue"),
            },
            {
                path: "events/:eventId/functions/:functionId/speakers/add",
                name: "Add Speaker",
                component: () => import("../views/admin/speakers/add.vue"),
            },
            {
                path: "users/",
                name: "Users",
                component: () => import("../views/admin/users/list.vue"),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to, from) => {
    window.parent?.postMessage(
        {
            type: "navigate",
        },
        "*"
    );
});

export default router;
